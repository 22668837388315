<template>
  <b-card-code title="FAQ">
    <b-card-text>Deskripsi</b-card-text>
    <quill-editor
      v-model="rowData.data.deskripsi"
      :options="snowOption"
    />

    <template #code>
      {{ codeSnow }}
    </template>
  </b-card-code>
</template>

<script>
import { BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import { codeSnow } from './code'

export default {
  components: {
    quillEditor,
    BCardCode,
    BCardText,
  },
  data() {
    return {
      codeSnow,
      snowOption: {
        theme: 'snow',
      },
    }
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
  },

}
</script>
